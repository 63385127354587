<template>
  <div class="wrapper">
    <Banner :img.sync="img" />
    <div class="container">
      <div class="title">
        <h1>{{ detail.title }}</h1>
        <p>
          <span v-if="detail.createdAt"
            >发布时间：{{ detail.createdAt.substring(0, 10) }}</span
          >
          <span v-if="detail.source">来源：{{ detail.source }}</span>
        </p>
      </div>
      <div class="content" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/Banner.vue";
export default {
  components: { Banner },
  data() {
    return {
      img: require("../assets/images/banner2.jpg"),
      detail: {
        title: "",
      },
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id;
      this.axios
        .get("https://s.icloudportal.com/api/siteReleasedPost", {
          params: {
            id: id,
          },
        })
        .then((response) => {
          this.detail = response.data.data;
          document.title = this.detail.title + " - 长春凯达";
          // console.log("res:", this.detail);
          this.$nextTick(() => {
            this.$loadLanguage(localStorage.getItem("lang"));
          });
        })
        .catch((error) => {
          console.log("err:", error);
        });
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  .container {
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
    padding: 30px 0;
    .title {
      text-align: center;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;
      h1 {
        font-size: 28px;
        line-height: 40px;
        font-weight: normal;
        text-align: center;
        padding: 10px 0;
      }
      p {
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        span {
          margin: 0 20px;
        }
      }
    }
    .content {
      padding-bottom: 80px;
      p {
        margin-bottom: 1em;
      }
      .align-right {
        text-align: right;
      }
    }
  }
}
</style>
